import { Component } from "react";
import Banner from "./Banner";
import portrait from "../images/portrait2.png";

class Home extends Component {
  render() {
    return (
      <div className="home">
        <Banner text={"Herzlich Willkommen"} />

        <div className="home-wrapper">
          <div className="portrait-wrapper">
            <img className="fade-in" src={portrait} alt="Dr. Sylvia Hackl" />
          </div>

          <div className="home-header">
            <h2 className="fade-in">Dr. Sylvia Hackl</h2>
            <div className="fade-in-1 supporting-text">
              Fachärztin für Haut- und Geschlechtskrankheiten
            </div>
          </div>

          <div className="home-information fade-in-2">
            <div className="wr">
              <div>
                <div className="info-wraper">
                  <p>Sehr geehrte Patientinnen und Patienten,</p>
                  <p>
                    Ich freue mich Ihnen mitteilen zu dürfen, dass ich meine <b>Kassenordination</b> mit Jänner 2025 eröffnen werde.
                    Sie wird sich in der Feschnigstraße 46, 9020 Klagenfurt, befinden, zusammen mit einer Augenärztin, Kinderärztin und einer Ärztin für Allgemeinmedizin.
                  </p>

                  <p>Die Anmeldung ist ab Oktober möglich.</p>

                  {/* <p>Termine können zur Zeit <b>nur</b> per Mail an <a href="mailto:praxis@derma-klagenfurt.at">praxis@derma-klagenfurt.at</a> entgegengenommen werden.</p> */}

                  <p>  Nähere Informationen folgen.</p>

                  <b> Bis dahin alles Gute und viel Gesundheit!</b>

                  <div class="highlighted-text"> Ihre Sylvia Hackl</div>
                </div>
              </div>
            </div>

            {/* <div className="wr">
              <div>
                <h3>Ordinationszeiten:</h3>

                <div className="info-wrapper">
                 <i className="fa fa-clock" />
                  <div>
                    <div className="time-slots">
                      <span>Dienstag:</span>
                      <span>07:30 - 15:00</span>
                    </div>
                    <div className="time-slots">
                      <span>Donnerstag:</span>
                      <span>07:30 - 17:00</span>
                    </div>
                  </div>
                </div>
              </div> */}

            {/* <div>
                <h3>Terminvereinbarung:</h3>

                <div className="info-wrapper">
                  <i className="fa fa-phone" /> 
                  <div>
                    <div className="time-slots">
                      <span>Mo - Do, 8 - 12 Uhr</span>
                    </div>

                    <div>
                      Tel:
                      <a href="tel:+43463512345">0463 512345</a>
                    </div>
                    <div>
                      E-Mail:
                      <a href="mailto:praxis@derma-klagenfurt.at">praxis@derma-klagenfurt.at</a>
                    </div>
                  </div>
                </div>
              </div>
            </div> */}
            {/* 
            <div>
              <h3>Anfahrt:</h3>
              <div className="info-wrapper">
                 <i className="fa fa-map-marker-alt" /> 
                <div>
                  <div className="office-information">
                    Praxisgemeinschaft Dr. Kobalter / Dr. Hackl
                  </div>
                  <div>9020 Klagenfurt am Wörthersee</div>
                  <div>St.Veiterstraße 85</div>
                </div>
              </div>
            </div> */}
            {/* <div>
              <iframe
                title="Google Maps Karte - Praxis Klagenfurt, St. Veiterstraße 85, 9020 Klagenfut"
                src={`https://maps.google.de/maps?hl=de&q=%20St.+Veiterstraße+85%20Klagenfurt+am+Wörthersee&t=&z=13&ie=utf8&iwloc=b&output=embed`}
                frameBorder="0"
                scrolling="no"
                marginHeight="0"
                marginWidth="0"
              ></iframe>
            </div> */}
          </div>
        </div>
      </div>
    );
  }
}

export default Home;
