import { Component } from "react";
import Banner from "./Banner";
import portrait from "../images/aboutme2.png";

class AboutMe extends Component {
  render() {
    return (
      <div className="about-me">
        <Banner text={"Über mich"} />

        <div className="about-me-wrapper">
          <img className="fade-in-2" src={portrait} alt="Dr. Sylvia Hackl" />
          <div className="personal-infos fade-in">
            <h2>Zur Person</h2>
            <ul>
              <li>Medizinstudium in Graz</li>
              <li>
                Turnus und Facharztausbildung im Allgäu / Deutschland und Klagenfurt am Wörthersee
              </li>
              <li>
                Fachärztin an der Abteilung für Dermatologie im Klinikum
                Klagenfurt
              </li>
              <li>
                <div>
                  <div>ÖAK-Diplome für Umweltmedizin, Arbeitsmedizin, Notfallmedizin</div>
                  <div>
                    ÖAK-Diplome für Psychosoziale und Psychosomatische Medizin,
                    Psychotherapeutische Medizin i. A.
                  </div>
                </div>
              </li>
              <li>Wund-Diplom-Arzt (nach Kammerlander)</li>
              <li>AGNES Neurodermitistrainerin</li>
              <li>
                Mitglied Arbeitsgruppe Allergie, Österreichischen Gesellschaft
                für Dermatologie und Venerologie (ÖGDV) und Österreichischen
                Gesellschaft für Dermatochirurgie (ÖGDC)
              </li>
            </ul>
          </div>
        </div>
      </div>
    );
  }
}

export default AboutMe;
